import "./i18n";
import "./styles/index.scss";

import App from "app/App";
import BrowserSupport from "app/BrowserSupport";
import ErrorBoundary from "app/ErrorBoundary";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "serviceWorker";

ReactDOM.render(
  // <StrictMode>
  <ErrorBoundary>
    <BrowserSupport>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </BrowserSupport>
  </ErrorBoundary>,
  // </StrictMode>,
  document.getElementById("root")
);

serviceWorker.register({
  onUpdate: () => {
    (window as any).__pageUpdated = true;
    window.dispatchEvent(new Event("dkv-page-updated"));
  },
});
