import React, {Component} from "react";
import styled from "styled-components/macro";

import LogoImage from "../assets/dkv.svg";

// -----------------------------------------------------------------------------------------------------------
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 100%;
  font-weight: bold;
  background-color: #ffffff;
  z-index: 9999;
  flex-direction: column;

  img {
    margin-bottom: 20px;
  }

  span {
    margin: 20px 0;
  }

  span.description {
    font-size: 16px;
    font-weight: normal;
  }

  p {
    margin: 0;

    a {
      margin-right: 20px;
    }

    a:last-of-type {
      margin-right: 0;
    }
  }
`;

// -----------------------------------------------------------------------------------------------------------
class BrowserSupport extends Component<React.PropsWithChildren<unknown>> {
  //#region render
  public render() {
    if (window.fetch !== undefined) {
      return this.props.children;
    }

    return (
      <Container>
        <img src={LogoImage} alt="DKV.Live" style={{width: "250px", marginBottom: "20px"}} />

        <span>Your Browser is not supported! / Ihr Browser ist veraltet!</span>
        <span className="description">
          Please use a modern evergreen browser. / Bitte aktualisieren Sie Ihren Browser.
        </span>
        <p>
          <a href="https://www.google.at/chrome/" target="_blank" rel="noopener noreferrer">
            Google Chrome
          </a>
          <a href="https://www.mozilla.org/de/firefox/new/" target="_blank" rel="noopener noreferrer">
            Mozilla Firefox
          </a>
          <a href="https://www.opera.com/" target="_blank" rel="noopener noreferrer">
            Opera
          </a>
          <a href="https://vivaldi.com/" target="_blank" rel="noopener noreferrer">
            Vivaldi
          </a>
          <a href="https://brave.com/" target="_blank" rel="noopener noreferrer">
            Brave
          </a>
        </p>
      </Container>
    );
  }
  //#endregion
}

export default BrowserSupport;
