import React, {useState, useEffect} from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Button, TextField} from "dkv-live-frontend-ui";
import {useFormik} from "formik";
import styled from "styled-components/macro";
import httpClient from "services/http";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {login} from "../state/login/actions";
import dayjs from "dayjs";

// -----------------------------------------------------------------------------------------------------------
const Error = styled.div`
  padding: 13px 20px;
  background-color: var(--dkv-highlight-primary-color);
  color: var(--dkv-highlight-primary-text-color);
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 1.167rem;
  width: 100%;
`;

const LoginButton = styled(Button)`
  font-size: 1.214286em;
  width: auto;
  min-width: 185px;
  margin: 48px auto auto auto;

  @media (max-height: 830px) {
    margin-top: 24;
  }
`;

const PWVisibilityIcon = styled.span`
  font-family: "DKV" !important;
  font-size: 20px;

  &:before {
    font-family: "DKV" !important;
  }
`;

const PWVisibilityOffIcon = styled.span`
  font-family: "DKV" !important;
  font-size: 20px;

  &:before {
    font-family: "DKV" !important;
  }
`;

const LoginPageLink = styled.button`
  display: block;
  margin-top: 20px;
  color: #666666;
  line-height: 1.25;
  font-size: 1.07143em;
  font-weight: normal;
  transition: color linear 0.1s;
  font-family: inherit;
  border: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    color: var(--dkv-text-primary-hover-color);
  }
`;

const LoginHeadline = styled.h1`
  margin-bottom: 72px;

  @media (max-height: 830px) {
    margin-bottom: 16px;
  }
`;

const LoginTextField = styled(TextField)`
  .MuiInputLabel-root {
    font-size: 1.1428571428571428em;
  }

  .MuiInputBase-input {
    font-size: 1.2142857142857142em;
  }
`;

// -----------------------------------------------------------------------------------------------------------
interface LoginFormProps {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  onLogin: () => void;
  setPage: (page: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({setLoading, onLogin, setPage}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  // form
  const [error, setError] = useState("");

  useEffect(() => {
    const loginError = localStorage.getItem("dkv-login-err");

    if (loginError) {
      setError("Login ungültig. Bitte loggen Sie sich neu ein.");
      localStorage.removeItem("dkv-login-err");
    }
  }, []);

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: false,
    },
    onSubmit: (values) => {
      setLoading(true);

      const doLogin = async () => {
        const formData = new FormData();

        formData.append("email", values.email);
        formData.append("password", values.password);

        const [{data}, err] = await httpClient.post("/management/auth/login", formData);
        if (err !== null || !data.session) {
          setLoading(false);
          setError("Benutzerdaten ungültig");
          return;
        }

        // @todo expire timeout
        localStorage.setItem("dkv-sess", data.session);

        // @todo set user data
        dayjs.locale("de");

        dispatch(
          login({
            id: data.user_id,
            displayName: "User Display Name",
            permissions: data.permissions,
            language: "de",
            timezone: "Europe/Vienna",
            dateFormat: "DD.MM.YYYY HH:mm:ss",
            dayFormat: "DD.MM.YYYY",
          })
        );
        onLogin();
      };

      doLogin();
    },
  });

  const toggleShowPassword = () => {
    loginForm.setFieldValue("showPassword", !loginForm.values.showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const changePageToPWLost = () => setPage("pwlost");

  // render
  return (
    <>
      <LoginHeadline>{t("Willkommen, bitte loggen Sie sich ein.")}</LoginHeadline>

      {error !== "" && <Error>{t(error)}</Error>}

      <form onSubmit={loginForm.handleSubmit} autoComplete="on">
        <LoginTextField
          name="email"
          type="email"
          required
          label={t("E-Mail Adresse")}
          value={loginForm.values.email}
          onChange={loginForm.handleChange}
          autoComplete="email"
        />

        <LoginTextField
          name="password"
          type={loginForm.values.showPassword ? "text" : "password"}
          required
          label={t("Password")}
          value={loginForm.values.password}
          onChange={loginForm.handleChange}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("Passwort umschalten")}
                  onClick={toggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {loginForm.values.showPassword ? (
                    <PWVisibilityIcon className="icon-ico_watching" />
                  ) : (
                    <PWVisibilityOffIcon className="icon-ico_doc_send" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoginPageLink type="button" onClick={changePageToPWLost}>
          {t("Passwort vergessen?")}
        </LoginPageLink>

        <LoginButton
          disabled={!loginForm.values.email || !loginForm.values.password}
          type="submit"
          ariaLabel={t("Anmelden")}
        >
          {t("Anmelden")}
        </LoginButton>
      </form>
    </>
  );
};

export default LoginForm;
