import {LoginAction} from "./types";
import {LoginState} from "../reducer";
import {_setLoggedInUser} from "./selectors";

// -----------------------------------------------------------------------------------------------------------
// reduce login action
export function reduceLogin(state: LoginState, action: LoginAction) {
  state.user = action.user;

  _setLoggedInUser(state.user);
}
