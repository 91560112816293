import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHRBackend from "i18next-xhr-backend";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import config from "config";
import httpClient from "services/http";


// -----------------------------------------------------------------------------------------------------------


// -----------------------------------------------------------------------------------------------------------
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: ["de", "en", "es", "cz", "sk", "ro"],
    fallbackLng: "de",
    ns: ["portal"],
    defaultNS: "portal",
    keySeparator: "::",
    nsSeparator: ":-:",
    pluralSeparator: "__",
    contextSeparator: "__",
    interpolation: {
      escapeValue: false,
      prefix: "{{.",
    },
    
    backend: {
      backends: [LocalStorageBackend, XHRBackend],
      backendOptions: [
        {
          prefix: "i18_res_",
          expirationTime: 2 * 24 * 60 * 60 * 1000,
          defaultVersion: process.env.NODE_ENV === "production" ? config.VERSION : new Date().getTime(),
        },
        {
          crossDomain: true,
          loadPath: "{{.ns}}/{{.lng}}",
          parse: (data: any) => data,
          async ajax(path: string, _options: any, callback: (resources: any, options: any) => void) {
            try {
              const [{data}, err] = await httpClient.get(`/i18n/translations/${path}`);
              if (err !== null) {
                console.error(err);
                return;
              }

              callback(data, {status: 200});
            } catch (e) {
              console.error(e);
            }
          },
        },
      ],
    },
  });

export default i18n;
