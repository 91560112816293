import React from "react";
import styled from "styled-components/macro";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

// -----------------------------------------------------------------------------------------------------------
const LoginPageLink = styled.button`
  display: block;
  margin-top: 20px;
  color: #666666;
  line-height: 1.25;
  font-size: 1.07143em;
  font-weight: normal;
  transition: color linear 0.1s;
  font-family: inherit;
  border: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    color: var(--dkv-text-primary-hover-color);
  }
`;

// -----------------------------------------------------------------------------------------------------------
interface PasswordLostProps {
  setPage: (page: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const PasswordLost: React.FC<PasswordLostProps> = ({setPage}) => {
  const {t} = useTranslation();
  const history = useHistory();

  const changePageToLogin = () => {
    setPage("login");
    history.push("/");
  };

  // render
  return (
    <>
      <h1>{t("Passwort zurücksetzen")}</h1>

      <p>
        {t(
          "Geben Sie bitte Ihre DKV live E-Mail-Adresse ein, mit der Sie sich registriert haben. Wir senden Ihnen eine E-Mail mit einem Link, mit dem Sie Ihr Passwort zurücksetzen können."
        )}
      </p>

      <LoginPageLink type="button" onClick={changePageToLogin}>
        {t("Zum Login")}
      </LoginPageLink>
    </>
  );
};

export default PasswordLost;
