import React, {useState} from "react";
import styled from "styled-components/macro";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {InputAdornment, IconButton} from "@material-ui/core";
import {TextField, Button} from "dkv-live-frontend-ui";

// -----------------------------------------------------------------------------------------------------------
const Error = styled.div`
  padding: 13px 20px;
  background-color: var(--dkv-highlight-primary-color);
  color: var(--dkv-highlight-primary-text-color);
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 1.167rem;
  width: 100%;
`;

const LoginTextField = styled(TextField)`
  .MuiInputLabel-root {
    font-size: 1.1428571428571428em;
  }

  .MuiInputBase-input {
    font-size: 1.2142857142857142em;
  }
`;

const PWVisibilityIcon = styled.span`
  font-family: "DKV" !important;
  font-size: 20px;

  &:before {
    font-family: "DKV" !important;
  }
`;

const PWVisibilityOffIcon = styled.span`
  font-family: "DKV" !important;
  font-size: 20px;

  &:before {
    font-family: "DKV" !important;
  }
`;

// -----------------------------------------------------------------------------------------------------------
const getQueryParam = (name: string) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

// -----------------------------------------------------------------------------------------------------------
interface ActivationProps {
  setPage: (page: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const Activation: React.FC<ActivationProps> = ({setLoading}) => {
  const {t} = useTranslation();

  // form
  const [error, setError] = useState("");

  const form = useFormik({
    initialValues: {
      password: "",
      password_again: "",
      showPassword: false,
      showPasswordAgain: false,
    },
    onSubmit: (values) => {
      const token = getQueryParam("token");

      if (values.password !== values.password_again) {
        setError(t("Passwörter stimmen nicht überein"));

        form.setSubmitting(false);
        return;
      }

      setLoading(true);
      setError("");

      console.log(`t:`, token); // nocheckin
      console.log(`values:`, values); // nocheckin
      setLoading(false);
    },
  });

  const toggleShowPassword = () => {
    form.setFieldValue("showPassword", !form.values.showPassword);
  };

  const toggleShowPasswordAgain = () => {
    form.setFieldValue("showPasswordAgain", !form.values.showPasswordAgain);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // render
  return (
    <>
      <h1>{t("Aktivierung")}</h1>

      <p>{t("Wählen Sie ein neues Passwort")}</p>

      {error !== "" && <Error>{t(error)}</Error>}

      <form onSubmit={form.handleSubmit} autoComplete="on">
        <LoginTextField
          name="password"
          type={form.values.showPassword ? "text" : "password"}
          required
          label={t("Password")}
          value={form.values.password}
          onChange={form.handleChange}
          autoComplete="current-password"
          inputProps={{minLength: 8}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("Passwort umschalten")}
                  onClick={toggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {form.values.showPassword ? (
                    <PWVisibilityIcon className="icon-ico_watching" />
                  ) : (
                    <PWVisibilityOffIcon className="icon-ico_doc_send" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoginTextField
          name="password_again"
          type={form.values.showPasswordAgain ? "text" : "password"}
          required
          label={t("Password wiederholen")}
          value={form.values.password_again}
          onChange={form.handleChange}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("Passwort umschalten")}
                  onClick={toggleShowPasswordAgain}
                  onMouseDown={handleMouseDownPassword}
                >
                  {form.values.showPasswordAgain ? (
                    <PWVisibilityIcon className="icon-ico_watching" />
                  ) : (
                    <PWVisibilityOffIcon className="icon-ico_doc_send" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          disabled={!form.values.password_again || !form.values.password}
          type="submit"
          ariaLabel={t("Ändern")}
        >
          {t("Ändern")}
        </Button>
      </form>
    </>
  );
};

export default Activation;
