import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import styled, {css} from "styled-components/macro";
import httpClient from "services/http";
import {useDispatch} from "react-redux";
import {login} from "./state/login/actions";
import LoginForm from "./components/LoginForm";
import PasswordLost from "./components/PasswordLost";
import FirstAdminLogin from "./components/FirstAdminLogin";
import Activation from "./components/Activation";
import {Loading} from "dkv-live-frontend-ui";
import dayjs from "dayjs";

import LogoImage from "../../../assets/dkvlive.svg";
import DKVLogoImage from "../../../assets/dkv.svg";
import SloganImage from "../../../assets/login.jpg";

// -----------------------------------------------------------------------------------------------------------
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

// -----------------------------------------------------------------------------------------------------------
interface LoginContainerProps {
  page?: string;
}

const LoginContainer = styled.div<LoginContainerProps>`
  width: 100%;
  max-width: 526px;
  flex-shrink: 0;
  background-color: #fff;
  padding: 130px 36px 12px 36px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;

  ${(props) =>
    props.page === "adminlogin" &&
    css`
      max-width: 800px;
    `}

  @media (max-height: 830px) {
    padding: 36px 36px 12px 36px;
  }
`;

const Logo = styled.img`
  height: 44px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  form {
    width: 100%;

    .MuiTextField-root {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  h1 {
    font-size: 1.285714em;
    font-weight: 500;
    margin-top: 108px;

    @media (max-height: 830px) {
      margin-top: 36px;
    }
  }

  p {
    font-size: 1.285714em;
    margin-top: 25px;
    margin-bottom: 32px;
    color: #666666;
  }
`;

const SloganContainer = styled.div`
  width: 100%;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(3.5px);
    backdrop-filter: blur(3.5px);
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const InfoLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto 32px 75px 32px;

  a {
    margin: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;

    a {
      margin: 8px 0;
    }
  }

  @media (max-height: 830px) {
    margin-bottom: 0;
  }
`;

const DKVLogoContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 17px;
  z-index: 2;

  img {
    width: 79px;
    margin-left: 21px;
    z-index: 2;
  }
`;

// -----------------------------------------------------------------------------------------------------------
interface LoginProps {
  onLogin: () => void;
}

const Login = ({onLogin}: LoginProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  // check if logged in
  useEffect(() => {
    const session = localStorage.getItem("dkv-sess");
    if (!session) {
      return;
    }

    setLoading(true);

    const load = async () => {
      const [{data}, err] = await httpClient.get("/management/auth/me");
      if (err !== null) {
        setLoading(false);
        return;
      }

      console.log("/me data", data);
      // @todo expire timeout

      // @todo set user data
      dayjs.locale("de");

      dispatch(
        login({
          id: data.user_id,
          displayName: "User Display Name",
          permissions: data.permissions,
          language: "de",
          timezone: "Europe/Vienna",
          dateFormat: "DD.MM.YYYY HH:mm:ss",
          dayFormat: "DD.MM.YYYY",
        })
      );
      onLogin();
    };

    load();
  }, [onLogin, dispatch]);

  // page
  let initialPage = "login";
  switch (window.location.pathname) {
    case "/welcome":
      initialPage = "adminlogin";
      break;
    case "/resetpassword":
      initialPage = "pwlost";
      break;
    case "/activation":
      initialPage = "activation";
      break;
  }
  const [page, setPage] = useState(initialPage);

  // render
  return (
    <Container>
      <LoginContainer page={page}>
        <Loading inprogress={loading} />

        <FormContainer>
          <Logo src={LogoImage} alt="DKV.Live" />

          {page === "login" && (
            <LoginForm loading={loading} setLoading={setLoading} onLogin={onLogin} setPage={setPage} />
          )}

          {page === "pwlost" && <PasswordLost setPage={setPage} loading={loading} setLoading={setLoading} />}

          {page === "adminlogin" && <FirstAdminLogin loading={loading} setLoading={setLoading} />}

          {page === "activation" && (
            <Activation setPage={setPage} loading={loading} setLoading={setLoading} />
          )}
        </FormContainer>

        <InfoLinks>
          <a href="/" target="_blank">
            {t("Nutzungsbedingungen")}
          </a>
          <a href="/" target="_blank">
            {t("Datenschutzerklärung")}
          </a>
          <a href="/" target="_blank">
            {t("Impressum")}
          </a>
        </InfoLinks>
      </LoginContainer>
      <SloganContainer>
        <img src={SloganImage} alt="DKV.Live" />
      </SloganContainer>

      <DKVLogoContainer>
        <p>You drive, we care.</p>
        <img src={DKVLogoImage} alt="DKV" />
      </DKVLogoContainer>
    </Container>
  );
};

export default Login;
