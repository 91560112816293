import {createReducer} from "@reduxjs/toolkit";
import {LOGIN, UserData} from "./login/types";
import {reduceLogin} from "./login/reducer";

export interface LoginState {
  user: UserData | null;
}

const initialState: LoginState = {
  user: null,
};

export const loginReducer = createReducer(initialState, {
  [LOGIN]: reduceLogin,
});
