import {LoginThunkResult} from "../module";
import {LOGIN} from "./types";
import {UserData} from "./types";

// login action
export function login(userData: UserData): LoginThunkResult<Promise<void>> {
  return async (dispatch) => {
    dispatch({type: LOGIN, user: userData});
  };
}
