import React, {useState, useEffect, useCallback} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Button, Loading} from "dkv-live-frontend-ui";
import {Close as CloseIcon} from "@styled-icons/material/Close";

// -----------------------------------------------------------------------------------------------------------
const Container = styled.div`
  background-color: var(--dkv-highlight-primary-color);
  color: var(--dkv-highlight-primary-text-color);
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 5000;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RefreshButton = styled(Button)`
  width: auto;
  font-size: 1em;
  padding: 3px 12px;
  color: var(--dkv-highlight-primary-text-color);
  margin-left: 4px;

  @media (max-width: 768px) {
    margin-top: 12px;
    margin-left: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  color: var(--dkv-highlight-primary-text-color);
  line-height: 1.25;
  font-size: 1.1426em;
  font-weight: 500;
  transition: opacity linear 0.1s;
  font-family: inherit;
  border: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

// -----------------------------------------------------------------------------------------------------------
const PageUpdated: React.FC = () => {
  const {t} = useTranslation();

  // updated
  const [updated, setUpdated] = useState((window as any).__pageUpdated || false);

  useEffect(() => {
    const onPageUpdated = () => setUpdated(true);

    window.addEventListener("dkv-page-updated", onPageUpdated);

    return () => {
      window.removeEventListener("dkv-page-updated", onPageUpdated);
    };
  });

  // message
  const [showMessage, setShowMessage] = useState(true);

  const hideMessage = useCallback(() => setShowMessage(false), [setShowMessage]);

  // reload
  const [loading, setLoading] = useState(false);

  const reload = useCallback(() => {
    const reloadWindow = async () => {
      if ("serviceWorker" in navigator) {
        const registration = await navigator.serviceWorker.getRegistration();

        if (registration && registration.waiting) {
          setLoading(true);

          const sw = registration.waiting;
          sw.onstatechange = () => {
            if (sw.state === "activated") {
              window.location.reload(true);
            }
          };

          sw.postMessage({type: "SKIP_WAITING"});
        } else {
          window.location.reload(true);
        }
      } else {
        window.location.reload(true);
      }
    };

    reloadWindow();
  }, [setLoading]);

  // render
  if (!updated || !showMessage) {
    return null;
  }

  return (
    <>
      <Loading inprogress={loading} zIndex={5001} />
      <Container>
        <span>{t("Eine neue Version ist verfügbar.")}</span>
        <RefreshButton compressed onClick={reload}>
          {t("Neu Laden")}
        </RefreshButton>
        <CloseButton onClick={hideMessage}>
          <CloseIcon size="20" />
        </CloseButton>
      </Container>
    </>
  );
};

export default PageUpdated;
