import axios, {AxiosResponse, AxiosRequestConfig, AxiosError, AxiosInterceptorManager} from "axios";
import config from "config";

// util to avoid try catch syntax
function to(promise: any): any {
  return promise.then((res: any) => [res, null]).catch((err: any) => [{}, err]);
}

// axios instance
const axiosInstance = axios.create({
  baseURL: config.API_URL,
  responseType: "json",
  withCredentials: true,
  transformRequest: [
    function(data, headers) {
      const sessionToken = localStorage.getItem("dkv-sess");
      if (sessionToken) {
        headers.Authorization = `Bearer ${sessionToken}`;
      }

      return data;
    },
  ],
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      window.dispatchEvent(new Event("dkv-logout"));
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

// http client
export interface HttpClient {
  defaults: AxiosRequestConfig;
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<[R, AxiosError | null]>;
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<[R, AxiosError | null]>;
  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<[R, AxiosError | null]>;
  head<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<[R, AxiosError | null]>;
  options<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<[R, AxiosError | null]>;
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<[R, AxiosError | null]>;
  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<[R, AxiosError | null]>;
  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<[R, AxiosError | null]>;
}

const client: HttpClient = {
  defaults: axiosInstance.defaults,
  interceptors: axiosInstance.interceptors,
  getUri: (config) => to(axiosInstance.getUri(config)),
  request: (config) => to(axiosInstance.request(config)),
  get: (url, config) => to(axiosInstance.get(url, config)),
  delete: (url, config) => to(axiosInstance.delete(url, config)),
  head: (url, config) => to(axiosInstance.head(url, config)),
  options: (url, config) => to(axiosInstance.options(url, config)),
  post: (url, data, config) => to(axiosInstance.post(url, data, config)),
  put: (url, data, config) => to(axiosInstance.put(url, data, config)),
  patch: (url, data, config) => to(axiosInstance.patch(url, data, config)),
};

export default client;
