import {createStore} from "redux-dynamic-modules";
import {getThunkExtension} from "redux-dynamic-modules-thunk";
import {LoginModule} from "../modules/login/state/module";

const store = createStore(
  {
    extensions: [getThunkExtension()],
  },
  LoginModule
);

export default store;
