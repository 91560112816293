import {IModule} from "redux-dynamic-modules";
import {LoginActionTypes} from "./actions";
import {ThunkAction} from "redux-thunk";
import {LoginState, loginReducer} from "./reducer";

export interface LoginModuleState {
  login: LoginState;
}

export type LoginThunkResult<R> = ThunkAction<R, LoginModuleState, void, LoginActionTypes>;

export const LoginModule: IModule<LoginModuleState> = {
  id: "login",
  reducerMap: {
    login: loginReducer,
  },
};
