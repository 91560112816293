import {LoginModuleState} from "../module";
import {UserData} from "./types";
import deLocale from "dayjs/locale/de";
import enLocale from "dayjs/locale/en";
import esLocale from "dayjs/locale/es";

// -----------------------------------------------------------------------------------------------------------
const dateLocaleMap = {
  de: deLocale,
  en: enLocale,
  es: esLocale,
};

// -----------------------------------------------------------------------------------------------------------
// loginUserSelector returns the user state.
export const loginUserSelector = (state: LoginModuleState) => state.login.user;

// -----------------------------------------------------------------------------------------------------------
// loggedInUser to save user in a "global" variable to allow easier and faster access.
let loggedInUser: UserData | null = null;

export const _setLoggedInUser = (u: UserData | null) => (loggedInUser = u);

// getLoggedInUser returns the logged in user.
export const getLoggedInUser = () => {
  return loggedInUser;
};

// hasPermission returns true if the user has the given permission.
export const hasPermission = (permission: string) => {
  if (!loggedInUser || !loggedInUser.permissions) {
    return false;
  }

  if (loggedInUser.permissions["superadmin:root"]) {
    return true;
  }

  return loggedInUser.permissions[permission];
};

// hasPermissions returns true if the user has all given permissions.
export const hasPermissions = (...permissions: string[]) => {
  if (!loggedInUser || !loggedInUser.permissions) {
    return false;
  }

  if (loggedInUser.permissions["superadmin:root"]) {
    return true;
  }

  for (const p in permissions) {
    if (!loggedInUser.permissions[p]) {
      return false;
    }
  }

  return true;
};

// hasOnePermission returns true if the user has atleast one of the given permissions.
export const hasOnePermission = (...permissions: string[]) => {
  if (!loggedInUser || !loggedInUser.permissions) {
    return false;
  }

  if (loggedInUser.permissions["superadmin:root"]) {
    return true;
  }

  for (const p in permissions) {
    if (loggedInUser.permissions[p]) {
      return true;
    }
  }

  return false;
};

// resolvePermissions returns all permissions the logged in user has.
export const resolvePermissions = () => {
  if (!loggedInUser || !loggedInUser.permissions) {
    return null;
  }

  return loggedInUser.permissions;
};

// resolveUserDateFormat returns the date format.
export const resolveUserDateFormat = (withSeconds = true) => {
  if (!loggedInUser) {
    return `DD.MM.YYYY HH:mm${withSeconds ? ":ss" : ""}`;
  }

  let format = loggedInUser.dateFormat;
  if (!withSeconds) {
    format = format.replace(":ss", "");
  }

  return format ?? `DD.MM.YYYY HH:mm${withSeconds ? ":ss" : ""}`;
};

// resolveUserDayFormat returns the day format.
export const resolveUserDayFormat = () => {
  if (!loggedInUser) {
    return `DD.MM.YYYY`;
  }

  return loggedInUser.dayFormat ?? "DD.MM.YYYY";
};

// resolveDateLocale returns the dayjs locale.
export const resolveUserDateLocale = () => {
  const language = resolveUserLanguage();

  const locale = dateLocaleMap[language];
  return locale ?? dateLocaleMap["de"];
};

// resolveUserLanguage returns the language.
export const resolveUserLanguage = () => {
  if (!loggedInUser) {
    return "de";
  }

  const language = loggedInUser.language;
  if (!dateLocaleMap[language]) {
    return "de";
  }

  return language;
};
