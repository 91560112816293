import React, {Component} from "react";
import styled from "styled-components/macro";
import {Button} from "dkv-live-frontend-ui";
import config from "config";

import LogoImage from "../assets/dkv.svg";

// -----------------------------------------------------------------------------------------------------------
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  background-color: #ffffff;
  z-index: 9999;
`;

const Info = styled.div`
  z-index: 2;
  padding: 10px;

  h1 {
    font-size: 3rem;
    margin: 10px 0;
  }

  h2 {
    font-size: 2rem;
    font-weight: normal;
    line-height: normal;
  }
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;

  button {
    max-width: 200px;
    margin-right: 12px;
  }
`;

// -----------------------------------------------------------------------------------------------------------
class ErrorBoundary extends Component<React.PropsWithChildren<unknown>> {
  //#region constructor
  public state = {
    hasError: false,
    errorData: null,
  };
  //#endregion

  //#region lifecycle
  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({hasError: true, errorData: {error, info}});
  }
  //#endregion

  //#region callbacks
  public _reload = () => {
    window.location.href = "/";
  };

  public _contact = () => {
    let err = btoa(JSON.stringify(this.state.errorData));
    if (err.length > 1000) {
      const errData = this.state.errorData ?? {error: "nA"};
      err = btoa(JSON.stringify(errData.error));
    }

    window.location.href = `mailto:${config.SUPPORT_EMAIL}?subject=Portal Error&body=Hello!%0D%0A%0D%0AInformations:%0D%0A%0D%0A(When did this error occur?)%0D%0A%0D%0A%0D%0A${err}%0D%0A`;
  };
  //#endregion

  //#region render
  public render() {
    if (this.state.hasError) {
      return (
        <Container>
          <img src={LogoImage} alt="DKV.Live" style={{width: "250px", marginBottom: "20px"}} />
          <Info>
            <h1>Oops!</h1>
            <h2>Looks like something went wrong...</h2>

            <Actions>
              <Button secondary onClick={this._contact}>
                Contact Support
              </Button>
              <Button onClick={this._reload}>DKV.Live</Button>
            </Actions>
          </Info>
        </Container>
      );
    }

    return this.props.children;
  }
  //#endregion
}

export default ErrorBoundary;
