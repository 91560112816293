import React, {useCallback, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import styled from "styled-components/macro";
import {Provider} from "react-redux";
import Login from "./modules/login/Login";

import store from "./state/store";
import {Loading} from "dkv-live-frontend-ui";
import PageUpdated from "./PageUpdated";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

// modules
const Live = React.lazy(() => import("./modules/live/Live"));
const Internal = React.lazy(() => import("./modules/internal/Internal"));

// -----------------------------------------------------------------------------------------------------------
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

// -----------------------------------------------------------------------------------------------------------
const App = () => {
  const [loggedIn, setLoggedIn] = useState(false); // nocheckin

  const onLogin = useCallback(() => {
    setLoggedIn(true);
  }, [setLoggedIn]);

  return (
    <Provider store={store}>
      <Router>
        <Container>
          {!loggedIn && <Login onLogin={onLogin} />}
          {loggedIn && (
            <>
              <React.Suspense fallback={<Loading inprogress />}>
                <Switch>
                  <Route path="/_internal">
                    <Internal />
                  </Route>
                  <Route path="/">
                    <Live />
                  </Route>
                </Switch>
              </React.Suspense>
            </>
          )}
          <PageUpdated />
        </Container>
      </Router>
    </Provider>
  );
};

export default App;
