export const LOGIN = "login/login";

export interface UserData {
  id: string;
  displayName: string;
  permissions: {[key: string]: boolean};
  language: string;
  timezone: string;
  dateFormat: string;
  dayFormat: string;
}

export interface LoginAction {
  type: typeof LOGIN;
  user: UserData;
}
