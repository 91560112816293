import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {
  Button,
  CheckboxField,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Message,
} from "dkv-live-frontend-ui";
import TagInput from "dkv-live-frontend-ui/lib/taginput";
import httpClient from "services/http";
import {InputAdornment, IconButton} from "@material-ui/core";

// -----------------------------------------------------------------------------------------------------------
const Headline = styled.h1`
  font-size: 1.7142857142857142rem !important;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 64px;

  .MuiTextField-root {
    margin-bottom: 0 !important;
  }

  @media (max-height: 830px) {
    margin-top: 32px;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
`;

const StyledCheckboxField = styled(CheckboxField)`
  margin: 48px 0 0 0;

  @media (max-height: 830px) {
    margin-top: 28px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

const FormMultiGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PWVisibilityIcon = styled.span`
  font-family: "DKV" !important;
  font-size: 20px;

  &:before {
    font-family: "DKV" !important;
  }
`;

const PWVisibilityOffIcon = styled.span`
  font-family: "DKV" !important;
  font-size: 20px;

  &:before {
    font-family: "DKV" !important;
  }
`;

// -----------------------------------------------------------------------------------------------------------
const getQueryParam = (name: string) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

// -----------------------------------------------------------------------------------------------------------
interface FirstAdminLoginProps {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const FirstAdminLogin: React.FC<FirstAdminLoginProps> = ({loading, setLoading}) => {
  const {t} = useTranslation();

  const [data, setData] = useState<any>(null);
  const [stage, setStage] = useState("init");
  const [error, setError] = useState("");

  // forward
  const forwardForm = useFormik<any>({
    initialValues: {
      email: "",
      lastname: "",
      firstname: "",
      token: "",
    },
    onSubmit: (values) => {
      const doForward = async () => {
        setError("");
        setLoading(true);

        const [, err] = await httpClient.post(
          `/management/users/adminlogin/forward`,
          JSON.stringify(values),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (err !== null) {
          setError(
            err.response?.data?.message
              ? err.response?.data?.message
              : typeof err === "string"
              ? err
              : t("Informationen konnten nicht weitergeleitet werden.")
          );
          forwardForm.setSubmitting(false);
          setLoading(false);
          return;
        }

        setLoading(false);
        setStage("forwarded");
      };

      doForward();
    },
  });

  const goToLogin = () => {
    window.location.href = "/";
  };

  // yes
  const doYes = async (values: any) => {
    const positions = values.positions.map((p: string) => {
      const tag = data.positions ? data.positions.find((pos: any) => pos.name === p) : undefined;

      const newPos: any = {
        name: p,
      };

      if (tag) {
        newPos.id = tag.id;
      }

      return newPos;
    });

    const [, err] = await httpClient.post(
      `/management/users/adminlogin/activate`,
      JSON.stringify({
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        positions: positions,
        token: values.token,
        password: values.password,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (err !== null) {
      setError(
        err.response?.data?.message
          ? err.response?.data?.message
          : typeof err === "string"
          ? err
          : t("User konnte nicht aktiviert werden")
      );
      setLoading(false);

      return false;
    }

    setLoading(false);
    setStage("done");

    return true;
  };

  const yesForm = useFormik<any>({
    initialValues: {
      email: "",
      lastname: "",
      firstname: "",
      password: "",
      password_again: "",
      positions: [],
      token: "",
      showPassword: false,
      showPasswordAgain: false,
    },
    onSubmit: (values) => {
      if (values.password !== values.password_again) {
        setError("Passwörter stimmen nicht überein");
        yesForm.setSubmitting(false);
        return;
      }

      setError("");
      setLoading(true);

      if (!doYes(values)) {
        yesForm.setSubmitting(false);
      }
    },
  });

  // nobutyes
  const nobutyesForm = useFormik<any>({
    initialValues: {
      email: "",
      lastname: "",
      firstname: "",
      password: "",
      password_again: "",
      positions: [],
      token: "",
      showPassword: false,
      showPasswordAgain: false,
    },
    onSubmit: (values) => {
      if (values.password !== values.password_again) {
        setError("Passwörter stimmen nicht überein");
        nobutyesForm.setSubmitting(false);
        return;
      }

      setError("");
      setLoading(true);

      if (!doYes(values)) {
        nobutyesForm.setSubmitting(false);
      }
    },
  });

  // init
  useEffect(() => {
    setError("");
    setLoading(true);

    const check = async () => {
      const token = getQueryParam("token");

      const [{data}, err] = await httpClient.get(`/management/users/adminlogin?token=${token}`);
      if (err !== null) {
        setError(t("Ungültiger Token"));
        setLoading(false);
        return;
      }

      setLoading(false);

      if (!data) {
        setError(t("Ungültiger Token"));
        return;
      }

      setData(data);
    };

    check();
  }, [setLoading, t]);

  const initForm = useFormik<any>({
    initialValues: {
      state: "1", // 1 = Ja, 2 = Nein aber befugt, 3 = nicht befugt
      confirm: false,
    },
    onSubmit: (values) => {
      let stage = "init";
      if (values.state === "3") {
        stage = "forward";

        forwardForm.setFieldValue("token", data.token);
      } else if (values.state === "1") {
        stage = "yes";

        yesForm.setValues({
          email: data.email,
          lastname: data.lastname,
          firstname: data.firstname,
          password: "",
          password_again: "",
          positions: data.positions ? data.positions.map((p: any) => p.name) : [],
          token: data.token,
          showPassword: false,
          showPasswordAgain: false,
        });
      } else if (values.state === "2") {
        stage = "nobutyes";

        nobutyesForm.setFieldValue("token", data.token);
      }

      setStage(stage);
    },
  });

  const toggleShowPassword = (form: any) => {
    form.setFieldValue("showPassword", !form.values.showPassword);
  };

  const toggleShowPasswordAgain = (form: any) => {
    form.setFieldValue("showPasswordAgain", !form.values.showPasswordAgain);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // render
  if (loading || !data) {
    return error ? <Message style={{width: "100%", marginTop: 32}} text={t(error)} error /> : null;
  }

  const defaultTags = [
    t("Geschäftsführung"),
    t("Fuhrparkleiter"),
    t("Werkstatt"),
    t("Verwaltung"),
    t("Disponent"),
  ];

  return (
    <>
      {error && <Message style={{width: "100%", marginTop: 32}} text={t(error)} error />}

      {stage === "done" && (
        <>
          <Headline>{t("Aktivierung abgeschlossen! Willkommen.")}</Headline>

          <Actions>
            <Button onClick={goToLogin} autoWidth>
              {t("Zum Login")}
            </Button>
          </Actions>
        </>
      )}

      {stage === "init" && (
        <>
          <Headline>
            {t("Guten Tag {{.name}}, Willkommen im DKV.live Universum!", {
              name: `${data.firstname} ${data.lastname}`,
            })}
          </Headline>

          <Form onSubmit={initForm.handleSubmit}>
            <RadioGroup
              name="state"
              value={initForm.values.state}
              onChange={(e: any) => {
                initForm.setFieldValue("state", e.target.value);
              }}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("Ja, ich bin {{.name}} und zur Anmeldung befugt", {
                  name: `${data.firstname} ${data.lastname}`,
                })}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t("Nein, ich bin nicht {{.name}} aber zur Anmeldung befugt", {
                  name: `${data.firstname} ${data.lastname}`,
                })}
              />
              <FormControlLabel value="3" control={<Radio />} label={t("Nein, ich bin nicht dazu befugt")} />
            </RadioGroup>

            <StyledCheckboxField
              id="welcome_state_1"
              label={t(
                "Hiermit bestätige ich die Richtigkeit meiner Angaben sowie, dass ich die Nutzungsbedingungen zur Kenntnis genommen habe."
              )}
              checked={initForm.values.confirm}
              onChange={() => initForm.setFieldValue("confirm", !initForm.values.confirm)}
            />

            <Actions>
              <Button type="submit" disabled={!initForm.values.confirm} autoWidth>
                {t("Bestätigen")}
              </Button>
            </Actions>
          </Form>
        </>
      )}

      {stage === "forward" && (
        <>
          <Headline>{t("Informationen an folgende Person weiterleiten")}:</Headline>

          <Form onSubmit={forwardForm.handleSubmit}>
            <FormGrid>
              <TextField
                name="email"
                type="email"
                label={t("E-Mail-Adresse")}
                value={forwardForm.values.email}
                onChange={forwardForm.handleChange}
                data-autofocus
                disabled={forwardForm.isSubmitting}
                variant="outlined"
                required
              />

              <TextField
                name="lastname"
                type="text"
                label={t("Nachname")}
                value={forwardForm.values.lastname}
                onChange={forwardForm.handleChange}
                data-autofocus
                disabled={forwardForm.isSubmitting}
                variant="outlined"
                required
                style={{marginTop: 24}}
              />

              <TextField
                name="firstname"
                type="text"
                label={t("Vorname")}
                value={forwardForm.values.firstname}
                onChange={forwardForm.handleChange}
                data-autofocus
                disabled={forwardForm.isSubmitting}
                variant="outlined"
                required
              />
            </FormGrid>
            <Actions>
              <Button
                type="button"
                secondary
                autoWidth
                onClick={() => setStage("init")}
                style={{marginRight: 40}}
              >
                {t("Abbrechen")}
              </Button>

              <Button
                type="submit"
                disabled={
                  !forwardForm.values.email || !forwardForm.values.lastname || !forwardForm.values.firstname
                }
                autoWidth
              >
                {t("Bestätigen")}
              </Button>
            </Actions>
          </Form>
        </>
      )}

      {stage === "forwarded" && (
        <>
          <Headline>{t("Vielen Dank!")}</Headline>

          <Actions>
            <Button type="button" secondary autoWidth onClick={goToLogin}>
              {t("Zum Login")}
            </Button>
          </Actions>
        </>
      )}

      {stage === "yes" && (
        <>
          <Headline>{t("Bitte überprüfen Sie Ihre Daten und legen sie ihr Passwort fest")}:</Headline>

          <Form onSubmit={yesForm.handleSubmit}>
            <FormMultiGrid>
              <TextField
                name="email"
                type="email"
                label={t("E-Mail-Adresse")}
                value={yesForm.values.email}
                onChange={yesForm.handleChange}
                data-autofocus
                disabled={yesForm.isSubmitting}
                variant="outlined"
                required
              />

              <TagInput
                value={yesForm.values.positions}
                onChange={(t) => yesForm.setFieldValue("positions", t)}
                options={defaultTags}
                label={t("Positionen")}
              />

              <TextField
                name="lastname"
                type="text"
                label={t("Nachname")}
                value={yesForm.values.lastname}
                onChange={yesForm.handleChange}
                disabled={yesForm.isSubmitting}
                variant="outlined"
                required
                style={{marginTop: 24}}
              />

              <TextField
                name="password"
                type={yesForm.values.showPassword ? "text" : "password"}
                label={t("Passwort")}
                value={yesForm.values.password}
                onChange={yesForm.handleChange}
                disabled={yesForm.isSubmitting}
                inputProps={{minLength: 8}}
                variant="outlined"
                required
                style={{marginTop: 24}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t("Passwort umschalten")}
                        onClick={() => toggleShowPassword(yesForm)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {yesForm.values.showPassword ? (
                          <PWVisibilityIcon className="icon-ico_watching" />
                        ) : (
                          <PWVisibilityOffIcon className="icon-ico_doc_send" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                name="firstname"
                type="text"
                label={t("Vorname")}
                value={yesForm.values.firstname}
                onChange={yesForm.handleChange}
                disabled={yesForm.isSubmitting}
                variant="outlined"
                required
              />

              <TextField
                name="password_again"
                type={yesForm.values.showPasswordAgain ? "text" : "password"}
                label={t("Passwort wiederholen")}
                value={yesForm.values.password_again}
                onChange={yesForm.handleChange}
                disabled={yesForm.isSubmitting}
                inputProps={{minLength: 8}}
                variant="outlined"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t("Passwort umschalten")}
                        onClick={() => toggleShowPasswordAgain(yesForm)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {yesForm.values.showPasswordAgain ? (
                          <PWVisibilityIcon className="icon-ico_watching" />
                        ) : (
                          <PWVisibilityOffIcon className="icon-ico_doc_send" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormMultiGrid>
            <Actions>
              <Button
                type="button"
                secondary
                autoWidth
                onClick={() => setStage("init")}
                style={{marginRight: 40}}
              >
                {t("Abbrechen")}
              </Button>

              <Button
                type="submit"
                disabled={
                  !yesForm.values.email ||
                  !yesForm.values.lastname ||
                  !yesForm.values.firstname ||
                  !yesForm.values.email ||
                  !yesForm.values.password ||
                  !yesForm.values.password_again
                }
                autoWidth
              >
                {t("Bestätigen")}
              </Button>
            </Actions>
          </Form>
        </>
      )}

      {stage === "nobutyes" && (
        <>
          <Headline>{t("Bitte geben sie Ihre Daten ein")}:</Headline>

          <Form onSubmit={nobutyesForm.handleSubmit}>
            <FormMultiGrid>
              <TextField
                name="email"
                type="email"
                label={t("E-Mail-Adresse")}
                value={nobutyesForm.values.email}
                onChange={nobutyesForm.handleChange}
                data-autofocus
                disabled={nobutyesForm.isSubmitting}
                variant="outlined"
                required
              />

              <TagInput
                value={nobutyesForm.values.positions}
                onChange={(t) => nobutyesForm.setFieldValue("positions", t)}
                options={defaultTags}
                label={t("Positionen")}
              />

              <TextField
                name="lastname"
                type="text"
                label={t("Nachname")}
                value={nobutyesForm.values.lastname}
                onChange={nobutyesForm.handleChange}
                disabled={nobutyesForm.isSubmitting}
                variant="outlined"
                required
                style={{marginTop: 24}}
              />

              <TextField
                name="password"
                type={nobutyesForm.values.showPassword ? "text" : "password"}
                label={t("Passwort")}
                value={nobutyesForm.values.password}
                onChange={nobutyesForm.handleChange}
                disabled={nobutyesForm.isSubmitting}
                variant="outlined"
                required
                style={{marginTop: 24}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t("Passwort umschalten")}
                        onClick={() => toggleShowPassword(nobutyesForm)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {nobutyesForm.values.showPassword ? (
                          <PWVisibilityIcon className="icon-ico_watching" />
                        ) : (
                          <PWVisibilityOffIcon className="icon-ico_doc_send" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                name="firstname"
                type="text"
                label={t("Vorname")}
                value={nobutyesForm.values.firstname}
                onChange={nobutyesForm.handleChange}
                disabled={nobutyesForm.isSubmitting}
                variant="outlined"
                required
              />

              <TextField
                name="password_again"
                type={nobutyesForm.values.showPasswordAgain ? "text" : "password"}
                label={t("Passwort wiederholen")}
                value={nobutyesForm.values.password_again}
                onChange={nobutyesForm.handleChange}
                disabled={nobutyesForm.isSubmitting}
                variant="outlined"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t("Passwort umschalten")}
                        onClick={() => toggleShowPasswordAgain(nobutyesForm)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {nobutyesForm.values.showPasswordAgain ? (
                          <PWVisibilityIcon className="icon-ico_watching" />
                        ) : (
                          <PWVisibilityOffIcon className="icon-ico_doc_send" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormMultiGrid>
            <Actions>
              <Button
                type="button"
                secondary
                autoWidth
                onClick={() => setStage("init")}
                style={{marginRight: 40}}
              >
                {t("Abbrechen")}
              </Button>

              <Button
                type="submit"
                disabled={
                  !nobutyesForm.values.email ||
                  !nobutyesForm.values.lastname ||
                  !nobutyesForm.values.firstname ||
                  !nobutyesForm.values.email ||
                  !nobutyesForm.values.password ||
                  !nobutyesForm.values.password_again
                }
                autoWidth
              >
                {t("Bestätigen")}
              </Button>
            </Actions>
          </Form>
        </>
      )}
    </>
  );
};

export default FirstAdminLogin;
